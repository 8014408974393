import { inject, Injectable } from '@angular/core';
import { Logger } from '@shared';
import { SliderIdentMappingService } from './slider-ident-mapping.service';
import { UsageQuantilesService } from './usage-type/usage-quantiles.service';
import { UsageSliderMappingService } from './usage-type/usage-slider-mapping.service';
import { BehaviorSubject, EMPTY, Observable, of, take } from 'rxjs';
import {
  MinergieStandard,
  RealEstateDTO,
  RealEstateUsageEmbeddable,
  SliderIntervalsOutputDTO,
  SliderValue,
  SliderValuesOutputDTO,
} from '@generated/generatedEntities';
import { RebuildEstateService } from '@app/core/services/rebuild/rebuild-estate.service';
import { SharedControllerFnService } from '@app/core/services/shared/shared-controller-fn.service';
import { GlobaltoastService } from '@app/core/services/GlobalToast/globaltoast.service';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

const log = new Logger('SliderPresetService');

export interface SliderPresetEvent {
  presetMark: number;
  key: string;
  interval: number[];
}

interface SliderValues {
  usages: RealEstateUsageEmbeddable[];
  realEstateContainer: RealEstateDTO;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SliderPresetService {
  private sliderIdentMappingService = inject(SliderIdentMappingService);
  private usageSliderMappingService = inject(UsageSliderMappingService);
  private usageQuantilesService = inject(UsageQuantilesService);
  private rebuildEstateService = inject(RebuildEstateService);
  private sharedControllerFnService = inject(SharedControllerFnService);
  private _globalToastService = inject(GlobaltoastService);
  private router = inject(Router);
  private presetEvent$: BehaviorSubject<SliderPresetEvent | null> = new BehaviorSubject<SliderPresetEvent | null>(null);
  private http = inject(HttpClient);

  constructor() {
    /**
     * this is to make sure that we clear the cached data from the behaviorSubject - otherwise the data gets emitted again but might be wrong.
     */
    this.router.events.pipe(untilDestroyed(this)).forEach((event) => {
      if (event instanceof NavigationStart) {
        this.presetEvent$.next(null);
      }
    });
  }

  presetEvent(): Observable<SliderPresetEvent | null> {
    return this.presetEvent$.asObservable();
  }

  private updatePresetEvent(value: SliderPresetEvent | null) {
    this.presetEvent$.next(value);
  }

  /*
   * Set default values of the sliders
   */
  setDefaultSliderModelValues(realEstate: any) {
    // Geometry and environment
    realEstate.quality.qualitaetDerGebaeudeform2 = 'WK5';

    // Material and front
    if (realEstate.metaData.withdrawalProductType == 'BUILDING_INSURANCE_VALUES') {
      realEstate.quality.qualitaetvorbereitungsarbeiten = null;
      realEstate.quality.qualitaetUmgebung = null;
    } else {
      realEstate.quality.qualitaetvorbereitungsarbeiten = 'WK5';
      realEstate.quality.qualitaetUmgebung = 'WK5';
    }
    realEstate.quality.qualitaetVorfertigung = 'WK4';
    realEstate.quality.qualitaetGrundstueck = 'WK5';
    realEstate.quality.fensterAnteil = 'WK5';

    // Technology
    realEstate.quality.qualitaetElektroAnlage = 'WK5';
    realEstate.quality.qualitaetHeizungsanlage = 'WK5';
    realEstate.quality.qualitaetSanitaeranlage = 'WK5';

    // Finishings
    realEstate.quality.qualitaetAusbau1 = 'WK5';
    realEstate.quality.qualitaetAusbau2 = 'WK5';
    log.debug('default SliderModelValues are set.');
    return realEstate;
  }

  /**
   * Additional amount for preaparatory work.
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {*}
   * @private
   */
  _amountPreparatoryWork(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2 && undergroundFloors < 3) {
      amount = Math.max(amount, 1);
    } else if (undergroundFloors >= 3 && undergroundFloors < 5) {
      amount = Math.max(amount, 2);
    } else if (undergroundFloors >= 5) {
      amount = Math.max(amount, 3);
    }
    if (overgroundFloors >= 4 && overgroundFloors < 7) {
      amount = Math.max(amount, 1);
    } else if (overgroundFloors >= 7 && overgroundFloors < 8) {
      amount = Math.max(amount, 2);
    } else if (overgroundFloors >= 8) {
      amount = Math.max(amount, 2);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountLot(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2) {
      amount = Math.max(amount, 1);
    }
    if (overgroundFloors >= 7) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountBuildingForm(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountSanitary(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (overgroundFloors >= 8) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */ _amountElectric(presetElectric: number, minergieStandard: MinergieStandard, pv: boolean): number {
    var amount = 0;
    if (minergieStandard === 'MINERGIE' || minergieStandard === 'MINERGIE_P') {
      if (presetElectric < 4) {
        amount = Math.max(amount, 1);
      }
    } else if (minergieStandard === 'MINERGIE_A') {
      if (presetElectric < 4) {
        amount = Math.max(amount, 2);
      } else if (presetElectric === 4) {
        amount = Math.max(amount, 1);
      }
    }
    if (pv) {
      if (presetElectric < 4) {
        amount = Math.max(amount, 1);
      }
    }
    return amount;
  }

  setPresetMark(key: string, presetMark: number, interval: number[]) {
    const updatePresetEvent: SliderPresetEvent = {
      key: key,
      presetMark: presetMark,
      interval: interval,
    };
    // log.warn(updatePresetEvent);
    this.updatePresetEvent(updatePresetEvent);
  }

  correctUsages(usages: RealEstateUsageEmbeddable[]): RealEstateUsageEmbeddable[] {
    // Object.assign creates only shallow copy! This causes problems
    //const tempUsages = Object.assign([], usages) as RealEstateUsageEmbeddable[];
    const tempUsages = _.cloneDeep(usages);
    const ret = this.sharedControllerFnService.cleanUsages(tempUsages);
    if (typeof tempUsages !== 'undefined' && tempUsages.length > 0 && tempUsages[0].type && tempUsages[0].standard) {
      if (tempUsages.length === 1) {
        return tempUsages;
      } else if (
        tempUsages.length === 2 &&
        (tempUsages[1].type === undefined || tempUsages[1].type === null || !tempUsages[1].type) &&
        (tempUsages[1].standard === undefined || tempUsages[1].standard === null || !tempUsages[1].standard)
      ) {
        //standard doesn't matter
        return [tempUsages[0]];
      } else if (tempUsages.length > 1 && this.isUsagesReady(tempUsages)) {
        //$broadcast('showSliderPresetTooltip');
        this._globalToastService.showSliderPresetTooltip$.next(true);
        return tempUsages;
        // Use outcommented code below if you want to remove VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE from usages
        /*
        $rootScope.$broadcast('showSliderPresetTooltip');
        var correctedUsages = [];
        for (var usage in usages) {
            var usageObj = usages[usage];
            if (usageObj.type !== 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE') {
                correctedUsages.push(usageObj);
            }
        }
        return correctedUsages;
         */
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  isUsagesReady(usages: RealEstateUsageEmbeddable[]) {
    try {
      var totalPercentage = 0;
      for (var usage in usages) {
        var usageObj = usages[usage];
        // continue if usages not selected (empty row)
        if (
          usageObj.type !== undefined &&
          usageObj.type !== null &&
          usageObj.standard !== undefined &&
          usageObj.standard !== null
        ) {
          if (!usageObj.percentage) {
            // usages set, but no percentage set => usages is not ready
            return false;
          } else {
            totalPercentage += Number(usageObj.percentage);
          }
        }
      }
      return totalPercentage === 100;
    } catch (error) {
      console.error('Unexpected error in isUsageReady', usages, error);
      return false;
    }
  }

  public requestPresetEvent2(realEstateContainer: any): Observable<any> {
    var usages = realEstateContainer.usages;
    if (
      realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS'
    ) {
      usages = realEstateContainer.targetOverhaul.usages;
    }
    var correctUsages = this.correctUsages(usages);
    if (correctUsages !== undefined) {
      return this.getWeightedPresetValues2(correctUsages, realEstateContainer).pipe(
        filter((weightedArr) => !!weightedArr),
        switchMap((weightedArr) =>
          this.getWeightedPresetIntervals2(correctUsages).pipe(
            take(1),
            tap((weightedIntervalArr) => {
              if (realEstateContainer.quality.fensterAnteil == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.fensterAnteil);
                realEstateContainer.quality.fensterAnteil = preset;
                realEstateContainer.quality.fensterAnteilBackend = preset;
                realEstateContainer.quality.fensterAnteilInterval = weightedIntervalArr.fensterAnteil;
              }

              if (realEstateContainer.quality.qualitaetDerGebaeudeform2 == null) {
                var preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetDerGebaeudeform2);
                var amountBuildingForm = this._amountBuildingForm(
                  realEstateContainer.geometry.overgroundFloors,
                  realEstateContainer.geometry.undergroundFloors,
                );

                preset = Math.min(preset + amountBuildingForm, 7);
                realEstateContainer.quality.qualitaetDerGebaeudeform2 = preset;
                realEstateContainer.quality.qualitaetDerGebaeudeform2Backend = preset;
                realEstateContainer.quality.qualitaetDerGebaeudeform2Interval =
                  weightedIntervalArr.qualitaetDerGebaeudeform2;
              }

              if (realEstateContainer.quality.qualitaetVorfertigung == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByLess(2);
                realEstateContainer.quality.qualitaetVorfertigung = preset;
                realEstateContainer.quality.qualitaetVorfertigungBackend = preset;
                realEstateContainer.quality.qualitaetVorfertigungInterval = undefined;
              }

              if (realEstateContainer.quality.qualitaetvorbereitungsarbeiten == null) {
                var amountVorbereitungsarbeiten = this._amountPreparatoryWork(
                  realEstateContainer.geometry.overgroundFloors,
                  realEstateContainer.geometry.undergroundFloors,
                );
                var preset = this.sliderIdentMappingService.getSliderIdentByMore(
                  weightedArr.qualitaetvorbereitungsarbeiten,
                );
                preset = Math.min(preset + amountVorbereitungsarbeiten, 7);
                realEstateContainer.quality.qualitaetvorbereitungsarbeiten = preset;
                realEstateContainer.quality.qualitaetvorbereitungsarbeitenBackend = preset;
                realEstateContainer.quality.qualitaetvorbereitungsarbeitenInterval =
                  weightedIntervalArr.qualitaetvorbereitungsarbeiten;
              }

              if (realEstateContainer.quality.qualitaetGrundstueck == null) {
                var amountLot = this._amountLot(
                  realEstateContainer.geometry.overgroundFloors,
                  realEstateContainer.geometry.undergroundFloors,
                );
                var preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetGrundstueck);
                preset = Math.min(preset + amountLot, 7);
                realEstateContainer.quality.qualitaetGrundstueck = preset;
                realEstateContainer.quality.qualitaetGrundstueckBackend = preset;
                realEstateContainer.quality.qualitaetGrundstueckInterval = weightedIntervalArr.qualitaetGrundstueck;
              }

              if (realEstateContainer.quality.qualitaetUmgebung == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetUmgebung);
                realEstateContainer.quality.qualitaetUmgebung = preset;
                realEstateContainer.quality.qualitaetUmgebungBackend = preset;
                realEstateContainer.quality.qualitaetUmgebungInterval = weightedIntervalArr.qualitaetUmgebung;
              }

              if (realEstateContainer.quality.qualitaetAusbau1 == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetAusbau1);
                realEstateContainer.quality.qualitaetAusbau1 = preset;
                realEstateContainer.quality.qualitaetAusbau1Backend = preset;
                realEstateContainer.quality.qualitaetAusbau1Interval = weightedIntervalArr.qualitaetAusbau1;
              }

              if (realEstateContainer.quality.qualitaetAusbau2 == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetAusbau2);
                realEstateContainer.quality.qualitaetAusbau2 = preset;
                realEstateContainer.quality.qualitaetAusbau2Backend = preset;
                realEstateContainer.quality.qualitaetAusbau2Interval = weightedIntervalArr.qualitaetAusbau2;
              }

              if (realEstateContainer.quality.qualitaetSanitaeranlage == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetSanitaeranlage);
                realEstateContainer.quality.qualitaetSanitaeranlage = preset;
                realEstateContainer.quality.qualitaetSanitaeranlageBackend = preset;
                realEstateContainer.quality.qualitaetSanitaeranlageInterval =
                  weightedIntervalArr.qualitaetSanitaeranlage;
              }

              if (realEstateContainer.quality.qualitaetHeizungsanlage == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetHeizungsanlage);
                realEstateContainer.quality.qualitaetHeizungsanlage = preset;
                realEstateContainer.quality.qualitaetHeizungsanlageBackend = preset;
                realEstateContainer.quality.qualitaetHeizungsanlageInterval =
                  weightedIntervalArr.qualitaetHeizungsanlage;
              }

              if (realEstateContainer.quality.qualitaetElektroAnlage == null) {
                const preset = this.sliderIdentMappingService.getSliderIdentByMore(weightedArr.qualitaetElektroAnlage);
                realEstateContainer.quality.qualitaetElektroAnlage = preset;
                realEstateContainer.quality.qualitaetElektroAnlageBackend = preset;
                realEstateContainer.quality.qualitaetElektroAnlageInterval = weightedIntervalArr.qualitaetElektroAnlage;
              }
              return realEstateContainer;
            }),
          ),
        ),
      );
    } else {
      return of(null);
    }
  }

  getWeightedPresetValues2(
    usages: RealEstateUsageEmbeddable[],
    realEstateContainer: RealEstateDTO,
    newValueMinergieStandard?: any,
    newValuePv?: any,
    newValueOvergroundFloors?: any,
    newValueUndergroundFloors?: any,
  ) {
    return this.getWeightedPresetValuesNew2(
      usages,
      realEstateContainer,
      newValueMinergieStandard,
      newValuePv,
      newValueOvergroundFloors,
      newValueUndergroundFloors,
    );
  }

  getWeightedPresetIntervals2(usages: RealEstateUsageEmbeddable[]) {
    return this.getWeightedPresetIntervalsNew2(usages);
  }

  private getWeightedPresetIntervalsNew2(usages: RealEstateUsageEmbeddable[]) {
    return this.http
      .post<SliderIntervalsOutputDTO>(`${environment.serverUrl}/api/preset/slider-intervals`, usages)
      .pipe(
        take(1),
        catchError(() => {
          return EMPTY;
        }),
      );
  }

  private getWeightedPresetValuesNew2(
    usages: RealEstateUsageEmbeddable[],
    realEstateContainer: RealEstateDTO,
    newValueMinergieStandard?: any,
    newValuePv?: any,
    newValueOvergroundFloors?: any,
    newValueUndergroundFloors?: any,
  ) {
    const sliderValues: SliderValues = { usages, realEstateContainer };
    return this.http
      .post<SliderValuesOutputDTO>(`${environment.serverUrl}/api/preset/slider-values`, sliderValues)
      .pipe(
        take(1),
        map((weightedArr) => {
          // apply special rules (Spezial-Regelwerk)
          // calc additional amounts
          var overgroundFloors = realEstateContainer.geometry.overgroundFloors;
          if (newValueOvergroundFloors !== undefined) {
            overgroundFloors = newValueOvergroundFloors;
          }
          var undergroundFloors = realEstateContainer.geometry.undergroundFloors;
          if (newValueUndergroundFloors !== undefined) {
            undergroundFloors = newValueUndergroundFloors;
          }
          var minergieStandard = realEstateContainer.quality.minergieStandard;
          if (newValueMinergieStandard != null) {
            minergieStandard = newValueMinergieStandard;
          }
          var pv = realEstateContainer.quality.pv;
          if (newValuePv != null) {
            pv = newValuePv;
          }

          if (!!weightedArr.qualitaetvorbereitungsarbeiten) {
            var amountPrep = this._amountPreparatoryWork(overgroundFloors, undergroundFloors);
            weightedArr.qualitaetvorbereitungsarbeiten = Math.min(
              weightedArr.qualitaetvorbereitungsarbeiten + amountPrep,
              7,
            );
          }
          var amountLot = this._amountLot(overgroundFloors, undergroundFloors);
          weightedArr.qualitaetGrundstueck = Math.min(weightedArr.qualitaetGrundstueck + amountLot, 7);

          var amountBuildingForm = this._amountBuildingForm(overgroundFloors, undergroundFloors);
          weightedArr.qualitaetDerGebaeudeform2 = Math.min(
            weightedArr.qualitaetDerGebaeudeform2 + amountBuildingForm,
            7,
          );

          var amountSanitary = this._amountSanitary(overgroundFloors, undergroundFloors);
          weightedArr.qualitaetSanitaeranlage = Math.min(weightedArr.qualitaetSanitaeranlage + amountSanitary, 7);

          var amountElectric = this._amountElectric(weightedArr.qualitaetElektroAnlage, minergieStandard, pv);
          weightedArr.qualitaetElektroAnlage = Math.min(weightedArr.qualitaetElektroAnlage + amountElectric, 7);
          return weightedArr;
        }),
        catchError(() => {
          return EMPTY;
        }),
      );
  }

  setSliderModelValuesArr2(
    preset_arr: SliderValuesOutputDTO,
    preset_i_arr: SliderIntervalsOutputDTO,
    realEstateContainer: RealEstateDTO,
    isPresetSliderModelValuesEnabled: boolean,
    filterSliderPropertyNamesArr?: string[],
  ) {
    var q = realEstateContainer.quality;

    // complexity
    var fensterAnteil = preset_arr.fensterAnteil;
    var i_fensterAnteil = preset_i_arr.fensterAnteil;
    var qualitaetDerGebaeudeform2 = preset_arr.qualitaetDerGebaeudeform2;
    var i_qualitaetDerGebaeudeform2 = preset_i_arr.qualitaetDerGebaeudeform2;
    var qualitaetVorfertigung = 2;
    var i_qualitaetVorfertigung: number[] | undefined = undefined;
    var qualitaetvorbereitungsarbeiten: number | null = null;
    var i_qualitaetvorbereitungsarbeiten: number[] | null = null;
    qualitaetvorbereitungsarbeiten = preset_arr.qualitaetvorbereitungsarbeiten;
    i_qualitaetvorbereitungsarbeiten = preset_i_arr.qualitaetvorbereitungsarbeiten;
    var qualitaetGrundstueck = preset_arr.qualitaetGrundstueck;
    var i_qualitaetGrundstueck = preset_i_arr.qualitaetGrundstueck;

    // quality
    var qualitaetUmgebung: number | null = null;
    var i_qualitaetUmgebung: number[] | null = null;
    qualitaetUmgebung = preset_arr.qualitaetUmgebung;
    i_qualitaetUmgebung = preset_i_arr.qualitaetUmgebung;
    var qualitaetAusbau1 = preset_arr.qualitaetAusbau1;
    var i_qualitaetAusbau1 = preset_i_arr.qualitaetAusbau1;
    var qualitaetAusbau2 = preset_arr.qualitaetAusbau2;
    var i_qualitaetAusbau2 = preset_i_arr.qualitaetAusbau2;
    var qualitaetSanitaeranlage = preset_arr.qualitaetSanitaeranlage;
    var i_qualitaetSanitaeranlage = preset_i_arr.qualitaetSanitaeranlage;
    var qualitaetHeizungsanlage = preset_arr.qualitaetHeizungsanlage;
    var i_qualitaetHeizungsanlage = preset_i_arr.qualitaetHeizungsanlage;
    var qualitaetElektroAnlage = preset_arr.qualitaetElektroAnlage;
    var i_qualitaetElektroAnlage = preset_i_arr.qualitaetElektroAnlage;

    if (isPresetSliderModelValuesEnabled) {
      if (!!filterSliderPropertyNamesArr) {
        // only set if any additional amount is present and beq 1
        if (filterSliderPropertyNamesArr.includes('qualitaetvorbereitungsarbeiten')) {
          q.qualitaetvorbereitungsarbeiten =
            this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
          q.qualitaetvorbereitungsarbeitenBackend = q.qualitaetvorbereitungsarbeiten as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetGrundstueck')) {
          q.qualitaetGrundstueck = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
          q.qualitaetGrundstueckBackend = q.qualitaetGrundstueck as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetDerGebaeudeform2')) {
          q.qualitaetDerGebaeudeform2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
          q.qualitaetDerGebaeudeform2Backend = q.qualitaetDerGebaeudeform2 as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetSanitaeranlage')) {
          q.qualitaetSanitaeranlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
          q.qualitaetSanitaeranlageBackend = q.qualitaetSanitaeranlage as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetElektroAnlage')) {
          q.qualitaetElektroAnlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);
          q.qualitaetElektroAnlageBackend = q.qualitaetElektroAnlage as SliderValue;
        }
      } else {
        q.fensterAnteil = this.sliderIdentMappingService.getSliderIdentByMore(fensterAnteil);
        q.qualitaetDerGebaeudeform2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
        q.qualitaetVorfertigung = this.sliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung);
        q.qualitaetvorbereitungsarbeiten =
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
        // setTimeout(() => {
        q.qualitaetGrundstueck = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
        // });
        q.qualitaetUmgebung = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung);
        q.qualitaetAusbau1 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1);
        q.qualitaetAusbau2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2);
        q.qualitaetSanitaeranlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
        q.qualitaetHeizungsanlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage);
        // setTimeout(() => {
        q.qualitaetElektroAnlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);
        // });

        //also setting backend values. if rebuild doesn't have all sliders visible, the backend values are not set - that's why we do it here.
        q.fensterAnteilBackend = q.fensterAnteil as SliderValue;
        q.qualitaetDerGebaeudeform2Backend = q.qualitaetDerGebaeudeform2 as SliderValue;
        q.qualitaetVorfertigungBackend = q.qualitaetVorfertigung as SliderValue;
        q.qualitaetvorbereitungsarbeitenBackend = q.qualitaetvorbereitungsarbeiten as SliderValue;
        q.qualitaetGrundstueckBackend = q.qualitaetGrundstueck as SliderValue;
        q.qualitaetUmgebungBackend = q.qualitaetUmgebung as SliderValue;
        q.qualitaetAusbau1Backend = q.qualitaetAusbau1 as SliderValue;
        q.qualitaetAusbau2Backend = q.qualitaetAusbau2 as SliderValue;
        q.qualitaetSanitaeranlageBackend = q.qualitaetSanitaeranlage as SliderValue;
        q.qualitaetHeizungsanlageBackend = q.qualitaetHeizungsanlage as SliderValue;
        q.qualitaetElektroAnlageBackend = q.qualitaetElektroAnlage as SliderValue;
      }
    }
    setTimeout(() => {
      this.setPresetMark(
        'realEstate.quality.fensterAnteil',
        this.sliderIdentMappingService.getSliderIdentByMore(fensterAnteil),
        i_fensterAnteil,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetDerGebaeudeform2',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2),
        i_qualitaetDerGebaeudeform2,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetVorfertigung',
        this.sliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung),
        i_qualitaetVorfertigung != undefined ? i_qualitaetVorfertigung : [],
      );
      if (qualitaetvorbereitungsarbeiten) {
        this.setPresetMark(
          'realEstate.quality.qualitaetvorbereitungsarbeiten',
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten),
          i_qualitaetvorbereitungsarbeiten != undefined ? i_qualitaetvorbereitungsarbeiten : [],
        );
      }
      this.setPresetMark(
        'realEstate.quality.qualitaetGrundstueck',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck),
        i_qualitaetGrundstueck,
      );
      if (qualitaetUmgebung) {
        this.setPresetMark(
          'realEstate.quality.qualitaetUmgebung',
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung),
          i_qualitaetUmgebung != undefined ? i_qualitaetUmgebung : [],
        );
      }
      this.setPresetMark(
        'realEstate.quality.qualitaetAusbau2',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2),
        i_qualitaetAusbau2,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetAusbau1',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1),
        i_qualitaetAusbau1,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetSanitaeranlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage),
        i_qualitaetSanitaeranlage,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetHeizungsanlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage),
        i_qualitaetHeizungsanlage,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetElektroAnlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage),
        i_qualitaetElektroAnlage,
      );
    });
  }
}
